import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(app)": [~4,[2]],
		"/(app)/analytics": [5,[2]],
		"/(app)/buy": [~6,[2]],
		"/(app)/buy/broadcast": [~7,[2]],
		"/(public)/forgot-password": [~31,[3]],
		"/(public)/invite": [~32,[3]],
		"/(public)/invite/invalid": [33,[3]],
		"/(public)/invite/success": [34,[3]],
		"/(public)/login": [~35,[3]],
		"/(public)/logout": [~36,[3]],
		"/(app)/negotiation/bid": [~16,[2]],
		"/(app)/negotiation/offer": [~17,[2]],
		"/(app)/negotiation/[negotiationId]": [~8,[2]],
		"/(app)/negotiation/[negotiationId]/accept": [~9,[2]],
		"/(app)/negotiation/[negotiationId]/cancel": [~10,[2]],
		"/(app)/negotiation/[negotiationId]/contract/[contractId]": [~11,[2]],
		"/(app)/negotiation/[negotiationId]/contract/[contractId]/addProducts": [~12,[2]],
		"/(app)/negotiation/[negotiationId]/contract/[contractId]/finalize": [~13,[2]],
		"/(app)/negotiation/[negotiationId]/counter": [~14,[2]],
		"/(app)/negotiation/[negotiationId]/reject": [~15,[2]],
		"/(public)/new-password": [~37,[3]],
		"/(app)/positions": [~18,[2]],
		"/(app)/positions/data": [~19,[2]],
		"/(app)/positions/data/create": [~21,[2]],
		"/(app)/positions/data/import": [22,[2]],
		"/(app)/positions/data/[type]/[positionId]/update": [~20,[2]],
		"/(app)/positions/deliveries": [~23,[2]],
		"/(app)/positions/examples": [24,[2]],
		"/(app)/products": [~25,[2]],
		"/(app)/products/create": [~27,[2]],
		"/(app)/products/[productId]": [~26,[2]],
		"/(public)/reset-password": [~38,[3]],
		"/(app)/sell": [~28,[2]],
		"/(app)/sell/broadcast": [~29,[2]],
		"/(app)/settings": [~30,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';